// source: protobuf/order/order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_export_pb = require('../../protobuf/filter/export_pb.js');
goog.object.extend(proto, protobuf_filter_export_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var protobuf_history_history_pb = require('../../protobuf/history/history_pb.js');
goog.object.extend(proto, protobuf_history_history_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.order.ApproveByBrokerRequest', null, global);
goog.exportSymbol('proto.order.CancelOrdersRequest', null, global);
goog.exportSymbol('proto.order.CancelOrdersResponse', null, global);
goog.exportSymbol('proto.order.CreateOrderItemRequest', null, global);
goog.exportSymbol('proto.order.CreateOrderRequest', null, global);
goog.exportSymbol('proto.order.DeleteOrdersRequest', null, global);
goog.exportSymbol('proto.order.DeleteOrdersResponse', null, global);
goog.exportSymbol('proto.order.ExportOrdersRequest', null, global);
goog.exportSymbol('proto.order.ExportOrdersResponse', null, global);
goog.exportSymbol('proto.order.GetExportersForClientRequest', null, global);
goog.exportSymbol('proto.order.GetLastOrderForClientAndExporterRequest', null, global);
goog.exportSymbol('proto.order.GetOrdersRequest', null, global);
goog.exportSymbol('proto.order.GetOrdersResponse', null, global);
goog.exportSymbol('proto.order.ListContractsRequest', null, global);
goog.exportSymbol('proto.order.ListContractsResponse', null, global);
goog.exportSymbol('proto.order.ListHistoryRequest', null, global);
goog.exportSymbol('proto.order.ListIDsResponse', null, global);
goog.exportSymbol('proto.order.ListOrdersRequest', null, global);
goog.exportSymbol('proto.order.ListOrdersResponse', null, global);
goog.exportSymbol('proto.order.SetCheckedDocTypeRequest', null, global);
goog.exportSymbol('proto.order.UpdateClientRequestedPriceRequest', null, global);
goog.exportSymbol('proto.order.UpdateClientResponsePriceRequest', null, global);
goog.exportSymbol('proto.order.UpdateContractRequest', null, global);
goog.exportSymbol('proto.order.UpdateDispatchDeliveryStatusRequest', null, global);
goog.exportSymbol('proto.order.UpdateDispatchLoadingStatusRequest', null, global);
goog.exportSymbol('proto.order.UpdateOrderItemRequest', null, global);
goog.exportSymbol('proto.order.UpdateOrderRequest', null, global);
goog.exportSymbol('proto.order.UpsertCargoItemsRequest', null, global);
goog.exportSymbol('proto.order.UpsertCargoItemsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpdateContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateContractRequest.displayName = 'proto.order.UpdateContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ListContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.ListContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ListContractsRequest.displayName = 'proto.order.ListContractsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ListContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.ListContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.order.ListContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ListContractsResponse.displayName = 'proto.order.ListContractsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.DeleteOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.DeleteOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.order.DeleteOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.DeleteOrdersRequest.displayName = 'proto.order.DeleteOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.DeleteOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.DeleteOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.DeleteOrdersResponse.displayName = 'proto.order.DeleteOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.CreateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.order.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.CreateOrderRequest.displayName = 'proto.order.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.UpdateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.order.UpdateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateOrderRequest.displayName = 'proto.order.UpdateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.CreateOrderItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.CreateOrderItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.CreateOrderItemRequest.displayName = 'proto.order.CreateOrderItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateOrderItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpdateOrderItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateOrderItemRequest.displayName = 'proto.order.UpdateOrderItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateClientResponsePriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpdateClientResponsePriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateClientResponsePriceRequest.displayName = 'proto.order.UpdateClientResponsePriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateClientRequestedPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpdateClientRequestedPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateClientRequestedPriceRequest.displayName = 'proto.order.UpdateClientRequestedPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpsertCargoItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.UpsertCargoItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.order.UpsertCargoItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpsertCargoItemsRequest.displayName = 'proto.order.UpsertCargoItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpsertCargoItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpsertCargoItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpsertCargoItemsResponse.displayName = 'proto.order.UpsertCargoItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.GetOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.GetOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.order.GetOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.GetOrdersRequest.displayName = 'proto.order.GetOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.GetOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.GetOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.GetOrdersResponse.displayName = 'proto.order.GetOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.GetLastOrderForClientAndExporterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.GetLastOrderForClientAndExporterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.GetLastOrderForClientAndExporterRequest.displayName = 'proto.order.GetLastOrderForClientAndExporterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.GetExportersForClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.GetExportersForClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.GetExportersForClientRequest.displayName = 'proto.order.GetExportersForClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ListIDsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.ListIDsResponse.repeatedFields_, null);
};
goog.inherits(proto.order.ListIDsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ListIDsResponse.displayName = 'proto.order.ListIDsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ListOrdersRequest.displayName = 'proto.order.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ListOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.ListOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.order.ListOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ListOrdersResponse.displayName = 'proto.order.ListOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ListHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.ListHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ListHistoryRequest.displayName = 'proto.order.ListHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ExportOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.ExportOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ExportOrdersRequest.displayName = 'proto.order.ExportOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ExportOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.ExportOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ExportOrdersResponse.displayName = 'proto.order.ExportOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.CancelOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.CancelOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.order.CancelOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.CancelOrdersRequest.displayName = 'proto.order.CancelOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.CancelOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.CancelOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.CancelOrdersResponse.displayName = 'proto.order.CancelOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateDispatchLoadingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpdateDispatchLoadingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateDispatchLoadingStatusRequest.displayName = 'proto.order.UpdateDispatchLoadingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.UpdateDispatchDeliveryStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.UpdateDispatchDeliveryStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.UpdateDispatchDeliveryStatusRequest.displayName = 'proto.order.UpdateDispatchDeliveryStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.ApproveByBrokerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.ApproveByBrokerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.ApproveByBrokerRequest.displayName = 'proto.order.ApproveByBrokerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.SetCheckedDocTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.SetCheckedDocTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.SetCheckedDocTypeRequest.displayName = 'proto.order.SetCheckedDocTypeRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transportationConditions: jspb.Message.getFieldWithDefault(msg, 6, ""),
    penaltyForCancellation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timeForLoadingAndCustomsClearance: jspb.Message.getFieldWithDefault(msg, 8, ""),
    timeForUnloadingAndCustomsClearance: jspb.Message.getFieldWithDefault(msg, 9, ""),
    checkingDocumentsByTheClient: jspb.Message.getFieldWithDefault(msg, 10, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    paymentPeriodDays: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentTerms: jspb.Message.getFieldWithDefault(msg, 13, ""),
    deliveryPeriod: jspb.Message.getFieldWithDefault(msg, 14, ""),
    prePayment: jspb.Message.getFieldWithDefault(msg, 15, ""),
    paymentAfterLoad: jspb.Message.getFieldWithDefault(msg, 16, ""),
    specialConditions: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isSignedByClient: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    isSignedByTransporter: jspb.Message.getBooleanFieldWithDefault(msg, 22, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateContractRequest}
 */
proto.order.UpdateContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateContractRequest;
  return proto.order.UpdateContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateContractRequest}
 */
proto.order.UpdateContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationConditions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPenaltyForCancellation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeForLoadingAndCustomsClearance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeForUnloadingAndCustomsClearance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckingDocumentsByTheClient(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentType(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymentPeriodDays(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentTerms(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryPeriod(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrePayment(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentAfterLoad(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialConditions(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSignedByClient(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSignedByTransporter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransportationConditions();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPenaltyForCancellation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimeForLoadingAndCustomsClearance();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTimeForUnloadingAndCustomsClearance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCheckingDocumentsByTheClient();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPaymentType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPaymentPeriodDays();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPaymentTerms();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeliveryPeriod();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPrePayment();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPaymentAfterLoad();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSpecialConditions();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsSignedByClient();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getIsSignedByTransporter();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transportation_conditions = 6;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getTransportationConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setTransportationConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string penalty_for_cancellation = 7;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getPenaltyForCancellation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setPenaltyForCancellation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string time_for_loading_and_customs_clearance = 8;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getTimeForLoadingAndCustomsClearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setTimeForLoadingAndCustomsClearance = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string time_for_unloading_and_customs_clearance = 9;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getTimeForUnloadingAndCustomsClearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setTimeForUnloadingAndCustomsClearance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string checking_documents_by_the_client = 10;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getCheckingDocumentsByTheClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setCheckingDocumentsByTheClient = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string payment_type = 11;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getPaymentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 payment_period_days = 12;
 * @return {number}
 */
proto.order.UpdateContractRequest.prototype.getPaymentPeriodDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setPaymentPeriodDays = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string payment_terms = 13;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getPaymentTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setPaymentTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string delivery_period = 14;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getDeliveryPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setDeliveryPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string pre_payment = 15;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getPrePayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setPrePayment = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string payment_after_load = 16;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getPaymentAfterLoad = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setPaymentAfterLoad = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string special_conditions = 20;
 * @return {string}
 */
proto.order.UpdateContractRequest.prototype.getSpecialConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setSpecialConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool is_signed_by_client = 21;
 * @return {boolean}
 */
proto.order.UpdateContractRequest.prototype.getIsSignedByClient = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setIsSignedByClient = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool is_signed_by_transporter = 22;
 * @return {boolean}
 */
proto.order.UpdateContractRequest.prototype.getIsSignedByTransporter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateContractRequest} returns this
 */
proto.order.UpdateContractRequest.prototype.setIsSignedByTransporter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ListContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ListContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ListContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ListContractsRequest}
 */
proto.order.ListContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ListContractsRequest;
  return proto.order.ListContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ListContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ListContractsRequest}
 */
proto.order.ListContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ListContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ListContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ListContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.order.ListContractsRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.ListContractsRequest} returns this
 */
proto.order.ListContractsRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional filter.Request filter = 2;
 * @return {?proto.filter.Request}
 */
proto.order.ListContractsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 2));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order.ListContractsRequest} returns this
*/
proto.order.ListContractsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ListContractsRequest} returns this
 */
proto.order.ListContractsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ListContractsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.ListContractsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ListContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ListContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ListContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.OrderContractResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ListContractsResponse}
 */
proto.order.ListContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ListContractsResponse;
  return proto.order.ListContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ListContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ListContractsResponse}
 */
proto.order.ListContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.OrderContractResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderContractResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ListContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ListContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ListContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.OrderContractResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.order.ListContractsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.order.ListContractsResponse} returns this
*/
proto.order.ListContractsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ListContractsResponse} returns this
 */
proto.order.ListContractsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ListContractsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.OrderContractResponse items = 2;
 * @return {!Array<!proto.model.OrderContractResponse>}
 */
proto.order.ListContractsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.OrderContractResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderContractResponse, 2));
};


/**
 * @param {!Array<!proto.model.OrderContractResponse>} value
 * @return {!proto.order.ListContractsResponse} returns this
*/
proto.order.ListContractsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.OrderContractResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderContractResponse}
 */
proto.order.ListContractsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.OrderContractResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.ListContractsResponse} returns this
 */
proto.order.ListContractsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.DeleteOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.DeleteOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.DeleteOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.DeleteOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.DeleteOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.DeleteOrdersRequest}
 */
proto.order.DeleteOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.DeleteOrdersRequest;
  return proto.order.DeleteOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.DeleteOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.DeleteOrdersRequest}
 */
proto.order.DeleteOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.DeleteOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.DeleteOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.DeleteOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.DeleteOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order.DeleteOrdersRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.DeleteOrdersRequest} returns this
 */
proto.order.DeleteOrdersRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.DeleteOrdersRequest} returns this
 */
proto.order.DeleteOrdersRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.DeleteOrdersRequest} returns this
 */
proto.order.DeleteOrdersRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.DeleteOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.DeleteOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.DeleteOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.DeleteOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.DeleteOrdersResponse}
 */
proto.order.DeleteOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.DeleteOrdersResponse;
  return proto.order.DeleteOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.DeleteOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.DeleteOrdersResponse}
 */
proto.order.DeleteOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.DeleteOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.DeleteOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.DeleteOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.DeleteOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.CreateOrderRequest.repeatedFields_ = [10,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exporterId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sourceAddressId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sourceAddressDetails: (f = msg.getSourceAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    destinationAddressId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationAddressDetails: (f = msg.getDestinationAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    customsAddressId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customsAddressDetails: (f = msg.getCustomsAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    isCustomsAddressSameAsSource: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    customsAdditionalAddressesIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    secondCustomsAddressId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    secondCustomsAddressDetails: (f = msg.getSecondCustomsAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    terminalAddressId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    dealType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    cargoItemsList: jspb.Message.toObjectList(msg.getCargoItemsList(),
    proto.order.CreateOrderItemRequest.toObject, includeInstance),
    weightUnit: jspb.Message.getFieldWithDefault(msg, 18, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 19, ""),
    clientRequestedPrice: jspb.Message.getFieldWithDefault(msg, 20, ""),
    clientRequestedPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    clientResponsePrice: jspb.Message.getFieldWithDefault(msg, 22, ""),
    brokerageServices: jspb.Message.getFieldWithDefault(msg, 30, 0),
    brokerageServicesCompanyId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    brokerageServicesCompanyDetails: (f = msg.getBrokerageServicesCompanyDetails()) && protobuf_model_merged_pb.BrokerageServicesCompanyDetails.toObject(includeInstance, f),
    exportServices: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    transitServices: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    loadDate: (f = msg.getLoadDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 36, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.CreateOrderRequest}
 */
proto.order.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.CreateOrderRequest;
  return proto.order.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.CreateOrderRequest}
 */
proto.order.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExporterId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceAddressId(value);
      break;
    case 4:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setSourceAddressDetails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationAddressId(value);
      break;
    case 6:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setDestinationAddressDetails(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomsAddressId(value);
      break;
    case 8:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setCustomsAddressDetails(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustomsAddressSameAsSource(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomsAdditionalAddressesIds(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondCustomsAddressId(value);
      break;
    case 14:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setSecondCustomsAddressDetails(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminalAddressId(value);
      break;
    case 16:
      var value = /** @type {!proto.model.DealType} */ (reader.readEnum());
      msg.setDealType(value);
      break;
    case 17:
      var value = new proto.order.CreateOrderItemRequest;
      reader.readMessage(value,proto.order.CreateOrderItemRequest.deserializeBinaryFromReader);
      msg.addCargoItems(value);
      break;
    case 18:
      var value = /** @type {!proto.model.WeightUnit} */ (reader.readEnum());
      msg.setWeightUnit(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPrice(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPriceCurrencyId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientResponsePrice(value);
      break;
    case 30:
      var value = /** @type {!proto.model.OrderBrokerageServices} */ (reader.readEnum());
      msg.setBrokerageServices(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerageServicesCompanyId(value);
      break;
    case 32:
      var value = new protobuf_model_merged_pb.BrokerageServicesCompanyDetails;
      reader.readMessage(value,protobuf_model_merged_pb.BrokerageServicesCompanyDetails.deserializeBinaryFromReader);
      msg.setBrokerageServicesCompanyDetails(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExportServices(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransitServices(value);
      break;
    case 35:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoadDate(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExporterId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceAddressId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSourceAddressDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getDestinationAddressId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationAddressDetails();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getCustomsAddressId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomsAddressDetails();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getIsCustomsAddressSameAsSource();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCustomsAdditionalAddressesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getSecondCustomsAddressId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSecondCustomsAddressDetails();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getTerminalAddressId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDealType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getCargoItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.order.CreateOrderItemRequest.serializeBinaryToWriter
    );
  }
  f = message.getWeightUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getClientRequestedPrice();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getClientRequestedPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getClientResponsePrice();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getBrokerageServices();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getBrokerageServicesCompanyId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getBrokerageServicesCompanyDetails();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      protobuf_model_merged_pb.BrokerageServicesCompanyDetails.serializeBinaryToWriter
    );
  }
  f = message.getExportServices();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getTransitServices();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getLoadDate();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exporter_id = 2;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getExporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setExporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string source_address_id = 3;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getSourceAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setSourceAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional model.OrderAddressDetails source_address_details = 4;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.CreateOrderRequest.prototype.getSourceAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 4));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setSourceAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearSourceAddressDetails = function() {
  return this.setSourceAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.hasSourceAddressDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string destination_address_id = 5;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getDestinationAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setDestinationAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional model.OrderAddressDetails destination_address_details = 6;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.CreateOrderRequest.prototype.getDestinationAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 6));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setDestinationAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearDestinationAddressDetails = function() {
  return this.setDestinationAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.hasDestinationAddressDetails = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string customs_address_id = 7;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getCustomsAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setCustomsAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional model.OrderAddressDetails customs_address_details = 8;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.CreateOrderRequest.prototype.getCustomsAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 8));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setCustomsAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearCustomsAddressDetails = function() {
  return this.setCustomsAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.hasCustomsAddressDetails = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_customs_address_same_as_source = 9;
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.getIsCustomsAddressSameAsSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setIsCustomsAddressSameAsSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated string customs_additional_addresses_ids = 10;
 * @return {!Array<string>}
 */
proto.order.CreateOrderRequest.prototype.getCustomsAdditionalAddressesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setCustomsAdditionalAddressesIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.addCustomsAdditionalAddressesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearCustomsAdditionalAddressesIdsList = function() {
  return this.setCustomsAdditionalAddressesIdsList([]);
};


/**
 * optional string second_customs_address_id = 13;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getSecondCustomsAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setSecondCustomsAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional model.OrderAddressDetails second_customs_address_details = 14;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.CreateOrderRequest.prototype.getSecondCustomsAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 14));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setSecondCustomsAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearSecondCustomsAddressDetails = function() {
  return this.setSecondCustomsAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.hasSecondCustomsAddressDetails = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string terminal_address_id = 15;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getTerminalAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setTerminalAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional model.DealType deal_type = 16;
 * @return {!proto.model.DealType}
 */
proto.order.CreateOrderRequest.prototype.getDealType = function() {
  return /** @type {!proto.model.DealType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.model.DealType} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setDealType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * repeated CreateOrderItemRequest cargo_items = 17;
 * @return {!Array<!proto.order.CreateOrderItemRequest>}
 */
proto.order.CreateOrderRequest.prototype.getCargoItemsList = function() {
  return /** @type{!Array<!proto.order.CreateOrderItemRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.CreateOrderItemRequest, 17));
};


/**
 * @param {!Array<!proto.order.CreateOrderItemRequest>} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setCargoItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.order.CreateOrderItemRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.CreateOrderItemRequest}
 */
proto.order.CreateOrderRequest.prototype.addCargoItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.order.CreateOrderItemRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearCargoItemsList = function() {
  return this.setCargoItemsList([]);
};


/**
 * optional model.WeightUnit weight_unit = 18;
 * @return {!proto.model.WeightUnit}
 */
proto.order.CreateOrderRequest.prototype.getWeightUnit = function() {
  return /** @type {!proto.model.WeightUnit} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.model.WeightUnit} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setWeightUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string weight = 19;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string client_requested_price = 20;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getClientRequestedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setClientRequestedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string client_requested_price_currency_id = 21;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getClientRequestedPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setClientRequestedPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string client_response_price = 22;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getClientResponsePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setClientResponsePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional model.OrderBrokerageServices brokerage_services = 30;
 * @return {!proto.model.OrderBrokerageServices}
 */
proto.order.CreateOrderRequest.prototype.getBrokerageServices = function() {
  return /** @type {!proto.model.OrderBrokerageServices} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.model.OrderBrokerageServices} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setBrokerageServices = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional string brokerage_services_company_id = 31;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getBrokerageServicesCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setBrokerageServicesCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional model.BrokerageServicesCompanyDetails brokerage_services_company_details = 32;
 * @return {?proto.model.BrokerageServicesCompanyDetails}
 */
proto.order.CreateOrderRequest.prototype.getBrokerageServicesCompanyDetails = function() {
  return /** @type{?proto.model.BrokerageServicesCompanyDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.BrokerageServicesCompanyDetails, 32));
};


/**
 * @param {?proto.model.BrokerageServicesCompanyDetails|undefined} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setBrokerageServicesCompanyDetails = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearBrokerageServicesCompanyDetails = function() {
  return this.setBrokerageServicesCompanyDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.hasBrokerageServicesCompanyDetails = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional bool export_services = 33;
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.getExportServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setExportServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool transit_services = 34;
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.getTransitServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setTransitServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional google.protobuf.Timestamp load_date = 35;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order.CreateOrderRequest.prototype.getLoadDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 35));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order.CreateOrderRequest} returns this
*/
proto.order.CreateOrderRequest.prototype.setLoadDate = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.clearLoadDate = function() {
  return this.setLoadDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.CreateOrderRequest.prototype.hasLoadDate = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional string comment = 36;
 * @return {string}
 */
proto.order.CreateOrderRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderRequest} returns this
 */
proto.order.CreateOrderRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.UpdateOrderRequest.repeatedFields_ = [11,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    exporterId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sourceAddressId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sourceAddressDetails: (f = msg.getSourceAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    destinationAddressId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    destinationAddressDetails: (f = msg.getDestinationAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    customsAddressId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    customsAddressDetails: (f = msg.getCustomsAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    isCustomsAddressSameAsSource: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    customsAdditionalAddressesIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    secondCustomsAddressId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    secondCustomsAddressDetails: (f = msg.getSecondCustomsAddressDetails()) && protobuf_model_merged_pb.OrderAddressDetails.toObject(includeInstance, f),
    terminalAddressId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    dealType: jspb.Message.getFieldWithDefault(msg, 21, 0),
    cargoItemsList: jspb.Message.toObjectList(msg.getCargoItemsList(),
    proto.order.UpdateOrderItemRequest.toObject, includeInstance),
    weightUnit: jspb.Message.getFieldWithDefault(msg, 25, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 26, ""),
    clientRequestedPrice: jspb.Message.getFieldWithDefault(msg, 31, ""),
    clientRequestedPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 32, ""),
    clientResponsePrice: jspb.Message.getFieldWithDefault(msg, 33, ""),
    brokerageServices: jspb.Message.getFieldWithDefault(msg, 41, 0),
    brokerageServicesCompanyId: jspb.Message.getFieldWithDefault(msg, 42, ""),
    brokerageServicesCompanyDetails: (f = msg.getBrokerageServicesCompanyDetails()) && protobuf_model_merged_pb.BrokerageServicesCompanyDetails.toObject(includeInstance, f),
    exportServices: jspb.Message.getBooleanFieldWithDefault(msg, 44, false),
    transitServices: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
    loadDate: (f = msg.getLoadDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 47, ""),
    dispatchLoadingStatus: jspb.Message.getFieldWithDefault(msg, 50, 0),
    dispatchDeliveryStatus: jspb.Message.getFieldWithDefault(msg, 51, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateOrderRequest}
 */
proto.order.UpdateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateOrderRequest;
  return proto.order.UpdateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateOrderRequest}
 */
proto.order.UpdateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExporterId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceAddressId(value);
      break;
    case 5:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setSourceAddressDetails(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationAddressId(value);
      break;
    case 7:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setDestinationAddressDetails(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomsAddressId(value);
      break;
    case 9:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setCustomsAddressDetails(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustomsAddressSameAsSource(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomsAdditionalAddressesIds(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondCustomsAddressId(value);
      break;
    case 13:
      var value = new protobuf_model_merged_pb.OrderAddressDetails;
      reader.readMessage(value,protobuf_model_merged_pb.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setSecondCustomsAddressDetails(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminalAddressId(value);
      break;
    case 21:
      var value = /** @type {!proto.model.DealType} */ (reader.readEnum());
      msg.setDealType(value);
      break;
    case 22:
      var value = new proto.order.UpdateOrderItemRequest;
      reader.readMessage(value,proto.order.UpdateOrderItemRequest.deserializeBinaryFromReader);
      msg.addCargoItems(value);
      break;
    case 25:
      var value = /** @type {!proto.model.WeightUnit} */ (reader.readEnum());
      msg.setWeightUnit(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPrice(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPriceCurrencyId(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientResponsePrice(value);
      break;
    case 41:
      var value = /** @type {!proto.model.OrderBrokerageServices} */ (reader.readEnum());
      msg.setBrokerageServices(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerageServicesCompanyId(value);
      break;
    case 43:
      var value = new protobuf_model_merged_pb.BrokerageServicesCompanyDetails;
      reader.readMessage(value,protobuf_model_merged_pb.BrokerageServicesCompanyDetails.deserializeBinaryFromReader);
      msg.setBrokerageServicesCompanyDetails(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExportServices(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransitServices(value);
      break;
    case 46:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoadDate(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 50:
      var value = /** @type {!proto.model.DispatchLoadingStatus} */ (reader.readEnum());
      msg.setDispatchLoadingStatus(value);
      break;
    case 51:
      var value = /** @type {!proto.model.DispatchDeliveryStatus} */ (reader.readEnum());
      msg.setDispatchDeliveryStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExporterId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSourceAddressId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSourceAddressDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getDestinationAddressId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDestinationAddressDetails();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getCustomsAddressId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCustomsAddressDetails();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getIsCustomsAddressSameAsSource();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCustomsAdditionalAddressesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getSecondCustomsAddressId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSecondCustomsAddressDetails();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      protobuf_model_merged_pb.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getTerminalAddressId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDealType();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getCargoItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.order.UpdateOrderItemRequest.serializeBinaryToWriter
    );
  }
  f = message.getWeightUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getClientRequestedPrice();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getClientRequestedPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getClientResponsePrice();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBrokerageServices();
  if (f !== 0.0) {
    writer.writeEnum(
      41,
      f
    );
  }
  f = message.getBrokerageServicesCompanyId();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getBrokerageServicesCompanyDetails();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      protobuf_model_merged_pb.BrokerageServicesCompanyDetails.serializeBinaryToWriter
    );
  }
  f = message.getExportServices();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getTransitServices();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getLoadDate();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getDispatchLoadingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getDispatchDeliveryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      51,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string exporter_id = 3;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getExporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setExporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string source_address_id = 4;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getSourceAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setSourceAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional model.OrderAddressDetails source_address_details = 5;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.UpdateOrderRequest.prototype.getSourceAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 5));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setSourceAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearSourceAddressDetails = function() {
  return this.setSourceAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.hasSourceAddressDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string destination_address_id = 6;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getDestinationAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setDestinationAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional model.OrderAddressDetails destination_address_details = 7;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.UpdateOrderRequest.prototype.getDestinationAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 7));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setDestinationAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearDestinationAddressDetails = function() {
  return this.setDestinationAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.hasDestinationAddressDetails = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string customs_address_id = 8;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getCustomsAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setCustomsAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional model.OrderAddressDetails customs_address_details = 9;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.UpdateOrderRequest.prototype.getCustomsAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 9));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setCustomsAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearCustomsAddressDetails = function() {
  return this.setCustomsAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.hasCustomsAddressDetails = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_customs_address_same_as_source = 10;
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.getIsCustomsAddressSameAsSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setIsCustomsAddressSameAsSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated string customs_additional_addresses_ids = 11;
 * @return {!Array<string>}
 */
proto.order.UpdateOrderRequest.prototype.getCustomsAdditionalAddressesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setCustomsAdditionalAddressesIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.addCustomsAdditionalAddressesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearCustomsAdditionalAddressesIdsList = function() {
  return this.setCustomsAdditionalAddressesIdsList([]);
};


/**
 * optional string second_customs_address_id = 12;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getSecondCustomsAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setSecondCustomsAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional model.OrderAddressDetails second_customs_address_details = 13;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.order.UpdateOrderRequest.prototype.getSecondCustomsAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.OrderAddressDetails, 13));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setSecondCustomsAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearSecondCustomsAddressDetails = function() {
  return this.setSecondCustomsAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.hasSecondCustomsAddressDetails = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string terminal_address_id = 14;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getTerminalAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setTerminalAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional model.DealType deal_type = 21;
 * @return {!proto.model.DealType}
 */
proto.order.UpdateOrderRequest.prototype.getDealType = function() {
  return /** @type {!proto.model.DealType} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.model.DealType} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setDealType = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * repeated UpdateOrderItemRequest cargo_items = 22;
 * @return {!Array<!proto.order.UpdateOrderItemRequest>}
 */
proto.order.UpdateOrderRequest.prototype.getCargoItemsList = function() {
  return /** @type{!Array<!proto.order.UpdateOrderItemRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.UpdateOrderItemRequest, 22));
};


/**
 * @param {!Array<!proto.order.UpdateOrderItemRequest>} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setCargoItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.order.UpdateOrderItemRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.UpdateOrderItemRequest}
 */
proto.order.UpdateOrderRequest.prototype.addCargoItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.order.UpdateOrderItemRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearCargoItemsList = function() {
  return this.setCargoItemsList([]);
};


/**
 * optional model.WeightUnit weight_unit = 25;
 * @return {!proto.model.WeightUnit}
 */
proto.order.UpdateOrderRequest.prototype.getWeightUnit = function() {
  return /** @type {!proto.model.WeightUnit} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.model.WeightUnit} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setWeightUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional string weight = 26;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string client_requested_price = 31;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getClientRequestedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setClientRequestedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string client_requested_price_currency_id = 32;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getClientRequestedPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setClientRequestedPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string client_response_price = 33;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getClientResponsePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setClientResponsePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional model.OrderBrokerageServices brokerage_services = 41;
 * @return {!proto.model.OrderBrokerageServices}
 */
proto.order.UpdateOrderRequest.prototype.getBrokerageServices = function() {
  return /** @type {!proto.model.OrderBrokerageServices} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {!proto.model.OrderBrokerageServices} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setBrokerageServices = function(value) {
  return jspb.Message.setProto3EnumField(this, 41, value);
};


/**
 * optional string brokerage_services_company_id = 42;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getBrokerageServicesCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setBrokerageServicesCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional model.BrokerageServicesCompanyDetails brokerage_services_company_details = 43;
 * @return {?proto.model.BrokerageServicesCompanyDetails}
 */
proto.order.UpdateOrderRequest.prototype.getBrokerageServicesCompanyDetails = function() {
  return /** @type{?proto.model.BrokerageServicesCompanyDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.BrokerageServicesCompanyDetails, 43));
};


/**
 * @param {?proto.model.BrokerageServicesCompanyDetails|undefined} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setBrokerageServicesCompanyDetails = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearBrokerageServicesCompanyDetails = function() {
  return this.setBrokerageServicesCompanyDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.hasBrokerageServicesCompanyDetails = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional bool export_services = 44;
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.getExportServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setExportServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional bool transit_services = 45;
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.getTransitServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setTransitServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional google.protobuf.Timestamp load_date = 46;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order.UpdateOrderRequest.prototype.getLoadDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 46));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order.UpdateOrderRequest} returns this
*/
proto.order.UpdateOrderRequest.prototype.setLoadDate = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.clearLoadDate = function() {
  return this.setLoadDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.UpdateOrderRequest.prototype.hasLoadDate = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional string comment = 47;
 * @return {string}
 */
proto.order.UpdateOrderRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional model.DispatchLoadingStatus dispatch_loading_status = 50;
 * @return {!proto.model.DispatchLoadingStatus}
 */
proto.order.UpdateOrderRequest.prototype.getDispatchLoadingStatus = function() {
  return /** @type {!proto.model.DispatchLoadingStatus} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.model.DispatchLoadingStatus} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setDispatchLoadingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional model.DispatchDeliveryStatus dispatch_delivery_status = 51;
 * @return {!proto.model.DispatchDeliveryStatus}
 */
proto.order.UpdateOrderRequest.prototype.getDispatchDeliveryStatus = function() {
  return /** @type {!proto.model.DispatchDeliveryStatus} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {!proto.model.DispatchDeliveryStatus} value
 * @return {!proto.order.UpdateOrderRequest} returns this
 */
proto.order.UpdateOrderRequest.prototype.setDispatchDeliveryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 51, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.CreateOrderItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.CreateOrderItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.CreateOrderItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CreateOrderItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adr: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    ansaControl: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    temperatureRegime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    palletStandard: jspb.Message.getFieldWithDefault(msg, 7, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 8, 0),
    length: jspb.Message.getFieldWithDefault(msg, 9, 0),
    height: jspb.Message.getFieldWithDefault(msg, 10, 0),
    width: jspb.Message.getFieldWithDefault(msg, 11, 0),
    weightUnit: jspb.Message.getFieldWithDefault(msg, 12, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 13, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 14, ""),
    sideLength: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.CreateOrderItemRequest}
 */
proto.order.CreateOrderItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.CreateOrderItemRequest;
  return proto.order.CreateOrderItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.CreateOrderItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.CreateOrderItemRequest}
 */
proto.order.CreateOrderItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdr(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnsaControl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperatureRegime(value);
      break;
    case 6:
      var value = /** @type {!proto.model.ItemType} */ (reader.readEnum());
      msg.setItemType(value);
      break;
    case 7:
      var value = /** @type {!proto.model.PalletStandard} */ (reader.readEnum());
      msg.setPalletStandard(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 12:
      var value = /** @type {!proto.model.WeightUnit} */ (reader.readEnum());
      msg.setWeightUnit(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSideLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.CreateOrderItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.CreateOrderItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.CreateOrderItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CreateOrderItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdr();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAnsaControl();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTemperatureRegime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPalletStandard();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getWeightUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSideLength();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string cargo_type_id = 1;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool adr = 3;
 * @return {boolean}
 */
proto.order.CreateOrderItemRequest.prototype.getAdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setAdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool ansa_control = 16;
 * @return {boolean}
 */
proto.order.CreateOrderItemRequest.prototype.getAnsaControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setAnsaControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string trailer_type_id = 4;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string temperature_regime = 5;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getTemperatureRegime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setTemperatureRegime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional model.ItemType item_type = 6;
 * @return {!proto.model.ItemType}
 */
proto.order.CreateOrderItemRequest.prototype.getItemType = function() {
  return /** @type {!proto.model.ItemType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.model.ItemType} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional model.PalletStandard pallet_standard = 7;
 * @return {!proto.model.PalletStandard}
 */
proto.order.CreateOrderItemRequest.prototype.getPalletStandard = function() {
  return /** @type {!proto.model.PalletStandard} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.model.PalletStandard} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setPalletStandard = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 quantity = 8;
 * @return {number}
 */
proto.order.CreateOrderItemRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 length = 9;
 * @return {number}
 */
proto.order.CreateOrderItemRequest.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 height = 10;
 * @return {number}
 */
proto.order.CreateOrderItemRequest.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 width = 11;
 * @return {number}
 */
proto.order.CreateOrderItemRequest.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional model.WeightUnit weight_unit = 12;
 * @return {!proto.model.WeightUnit}
 */
proto.order.CreateOrderItemRequest.prototype.getWeightUnit = function() {
  return /** @type {!proto.model.WeightUnit} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.model.WeightUnit} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setWeightUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string weight = 13;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string volume = 14;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string side_length = 15;
 * @return {string}
 */
proto.order.CreateOrderItemRequest.prototype.getSideLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CreateOrderItemRequest} returns this
 */
proto.order.CreateOrderItemRequest.prototype.setSideLength = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateOrderItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateOrderItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateOrderItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateOrderItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adr: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    ansaControl: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    temperatureRegime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    palletStandard: jspb.Message.getFieldWithDefault(msg, 8, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    length: jspb.Message.getFieldWithDefault(msg, 10, 0),
    height: jspb.Message.getFieldWithDefault(msg, 11, 0),
    width: jspb.Message.getFieldWithDefault(msg, 12, 0),
    weightUnit: jspb.Message.getFieldWithDefault(msg, 13, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 14, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 15, ""),
    sideLength: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateOrderItemRequest}
 */
proto.order.UpdateOrderItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateOrderItemRequest;
  return proto.order.UpdateOrderItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateOrderItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateOrderItemRequest}
 */
proto.order.UpdateOrderItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdr(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnsaControl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperatureRegime(value);
      break;
    case 7:
      var value = /** @type {!proto.model.ItemType} */ (reader.readEnum());
      msg.setItemType(value);
      break;
    case 8:
      var value = /** @type {!proto.model.PalletStandard} */ (reader.readEnum());
      msg.setPalletStandard(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 13:
      var value = /** @type {!proto.model.WeightUnit} */ (reader.readEnum());
      msg.setWeightUnit(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSideLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateOrderItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateOrderItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateOrderItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateOrderItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdr();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAnsaControl();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTemperatureRegime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPalletStandard();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getWeightUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSideLength();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cargo_type_id = 2;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool adr = 4;
 * @return {boolean}
 */
proto.order.UpdateOrderItemRequest.prototype.getAdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setAdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool ansa_control = 17;
 * @return {boolean}
 */
proto.order.UpdateOrderItemRequest.prototype.getAnsaControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setAnsaControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional string trailer_type_id = 5;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string temperature_regime = 6;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getTemperatureRegime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setTemperatureRegime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional model.ItemType item_type = 7;
 * @return {!proto.model.ItemType}
 */
proto.order.UpdateOrderItemRequest.prototype.getItemType = function() {
  return /** @type {!proto.model.ItemType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.model.ItemType} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional model.PalletStandard pallet_standard = 8;
 * @return {!proto.model.PalletStandard}
 */
proto.order.UpdateOrderItemRequest.prototype.getPalletStandard = function() {
  return /** @type {!proto.model.PalletStandard} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.model.PalletStandard} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setPalletStandard = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 quantity = 9;
 * @return {number}
 */
proto.order.UpdateOrderItemRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 length = 10;
 * @return {number}
 */
proto.order.UpdateOrderItemRequest.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 height = 11;
 * @return {number}
 */
proto.order.UpdateOrderItemRequest.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 width = 12;
 * @return {number}
 */
proto.order.UpdateOrderItemRequest.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional model.WeightUnit weight_unit = 13;
 * @return {!proto.model.WeightUnit}
 */
proto.order.UpdateOrderItemRequest.prototype.getWeightUnit = function() {
  return /** @type {!proto.model.WeightUnit} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.model.WeightUnit} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setWeightUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string weight = 14;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string volume = 15;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string side_length = 16;
 * @return {string}
 */
proto.order.UpdateOrderItemRequest.prototype.getSideLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateOrderItemRequest} returns this
 */
proto.order.UpdateOrderItemRequest.prototype.setSideLength = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateClientResponsePriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateClientResponsePriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateClientResponsePriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateClientResponsePriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientResponsePrice: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateClientResponsePriceRequest}
 */
proto.order.UpdateClientResponsePriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateClientResponsePriceRequest;
  return proto.order.UpdateClientResponsePriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateClientResponsePriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateClientResponsePriceRequest}
 */
proto.order.UpdateClientResponsePriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientResponsePrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateClientResponsePriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateClientResponsePriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateClientResponsePriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateClientResponsePriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientResponsePrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.UpdateClientResponsePriceRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateClientResponsePriceRequest} returns this
 */
proto.order.UpdateClientResponsePriceRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_response_price = 2;
 * @return {string}
 */
proto.order.UpdateClientResponsePriceRequest.prototype.getClientResponsePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateClientResponsePriceRequest} returns this
 */
proto.order.UpdateClientResponsePriceRequest.prototype.setClientResponsePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateClientRequestedPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateClientRequestedPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateClientRequestedPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientRequestedPrice: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientRequestedPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateClientRequestedPriceRequest}
 */
proto.order.UpdateClientRequestedPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateClientRequestedPriceRequest;
  return proto.order.UpdateClientRequestedPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateClientRequestedPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateClientRequestedPriceRequest}
 */
proto.order.UpdateClientRequestedPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPriceCurrencyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateClientRequestedPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateClientRequestedPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateClientRequestedPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientRequestedPrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientRequestedPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateClientRequestedPriceRequest} returns this
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_requested_price = 2;
 * @return {string}
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.getClientRequestedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateClientRequestedPriceRequest} returns this
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.setClientRequestedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_requested_price_currency_id = 3;
 * @return {string}
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.getClientRequestedPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateClientRequestedPriceRequest} returns this
 */
proto.order.UpdateClientRequestedPriceRequest.prototype.setClientRequestedPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.UpsertCargoItemsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpsertCargoItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpsertCargoItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpsertCargoItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpsertCargoItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cargoItemsList: jspb.Message.toObjectList(msg.getCargoItemsList(),
    proto.order.UpdateOrderItemRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpsertCargoItemsRequest}
 */
proto.order.UpsertCargoItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpsertCargoItemsRequest;
  return proto.order.UpsertCargoItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpsertCargoItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpsertCargoItemsRequest}
 */
proto.order.UpsertCargoItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = new proto.order.UpdateOrderItemRequest;
      reader.readMessage(value,proto.order.UpdateOrderItemRequest.deserializeBinaryFromReader);
      msg.addCargoItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpsertCargoItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpsertCargoItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpsertCargoItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpsertCargoItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCargoItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.order.UpdateOrderItemRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.UpsertCargoItemsRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpsertCargoItemsRequest} returns this
 */
proto.order.UpsertCargoItemsRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UpdateOrderItemRequest cargo_items = 3;
 * @return {!Array<!proto.order.UpdateOrderItemRequest>}
 */
proto.order.UpsertCargoItemsRequest.prototype.getCargoItemsList = function() {
  return /** @type{!Array<!proto.order.UpdateOrderItemRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.UpdateOrderItemRequest, 3));
};


/**
 * @param {!Array<!proto.order.UpdateOrderItemRequest>} value
 * @return {!proto.order.UpsertCargoItemsRequest} returns this
*/
proto.order.UpsertCargoItemsRequest.prototype.setCargoItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.order.UpdateOrderItemRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.UpdateOrderItemRequest}
 */
proto.order.UpsertCargoItemsRequest.prototype.addCargoItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.order.UpdateOrderItemRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.UpsertCargoItemsRequest} returns this
 */
proto.order.UpsertCargoItemsRequest.prototype.clearCargoItemsList = function() {
  return this.setCargoItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpsertCargoItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpsertCargoItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpsertCargoItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpsertCargoItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.OrderItemResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpsertCargoItemsResponse}
 */
proto.order.UpsertCargoItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpsertCargoItemsResponse;
  return proto.order.UpsertCargoItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpsertCargoItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpsertCargoItemsResponse}
 */
proto.order.UpsertCargoItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.OrderItemResponse.deserializeBinaryFromReader, "", new proto.model.OrderItemResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpsertCargoItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpsertCargoItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpsertCargoItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpsertCargoItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.OrderItemResponse.serializeBinaryToWriter);
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.UpsertCargoItemsResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpsertCargoItemsResponse} returns this
 */
proto.order.UpsertCargoItemsResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, model.OrderItemResponse> items = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.OrderItemResponse>}
 */
proto.order.UpsertCargoItemsResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.OrderItemResponse>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.model.OrderItemResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.order.UpsertCargoItemsResponse} returns this
 */
proto.order.UpsertCargoItemsResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.GetOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.GetOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.GetOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.GetOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.GetOrdersRequest}
 */
proto.order.GetOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.GetOrdersRequest;
  return proto.order.GetOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.GetOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.GetOrdersRequest}
 */
proto.order.GetOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.GetOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.GetOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.GetOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order.GetOrdersRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.GetOrdersRequest} returns this
 */
proto.order.GetOrdersRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.GetOrdersRequest} returns this
 */
proto.order.GetOrdersRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.GetOrdersRequest} returns this
 */
proto.order.GetOrdersRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.GetOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.GetOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.GetOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.OrderResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.GetOrdersResponse}
 */
proto.order.GetOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.GetOrdersResponse;
  return proto.order.GetOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.GetOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.GetOrdersResponse}
 */
proto.order.GetOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.OrderResponse.deserializeBinaryFromReader, "", new proto.model.OrderResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.GetOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.GetOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.GetOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.OrderResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, model.OrderResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.OrderResponse>}
 */
proto.order.GetOrdersResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.OrderResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.OrderResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.order.GetOrdersResponse} returns this
 */
proto.order.GetOrdersResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.GetLastOrderForClientAndExporterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.GetLastOrderForClientAndExporterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.GetLastOrderForClientAndExporterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetLastOrderForClientAndExporterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exporterId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.GetLastOrderForClientAndExporterRequest}
 */
proto.order.GetLastOrderForClientAndExporterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.GetLastOrderForClientAndExporterRequest;
  return proto.order.GetLastOrderForClientAndExporterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.GetLastOrderForClientAndExporterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.GetLastOrderForClientAndExporterRequest}
 */
proto.order.GetLastOrderForClientAndExporterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExporterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.GetLastOrderForClientAndExporterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.GetLastOrderForClientAndExporterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.GetLastOrderForClientAndExporterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetLastOrderForClientAndExporterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExporterId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.order.GetLastOrderForClientAndExporterRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.GetLastOrderForClientAndExporterRequest} returns this
 */
proto.order.GetLastOrderForClientAndExporterRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exporter_id = 2;
 * @return {string}
 */
proto.order.GetLastOrderForClientAndExporterRequest.prototype.getExporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.GetLastOrderForClientAndExporterRequest} returns this
 */
proto.order.GetLastOrderForClientAndExporterRequest.prototype.setExporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.GetExportersForClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.GetExportersForClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.GetExportersForClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetExportersForClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.GetExportersForClientRequest}
 */
proto.order.GetExportersForClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.GetExportersForClientRequest;
  return proto.order.GetExportersForClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.GetExportersForClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.GetExportersForClientRequest}
 */
proto.order.GetExportersForClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.GetExportersForClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.GetExportersForClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.GetExportersForClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.GetExportersForClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.order.GetExportersForClientRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.GetExportersForClientRequest} returns this
 */
proto.order.GetExportersForClientRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.ListIDsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ListIDsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ListIDsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ListIDsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListIDsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ListIDsResponse}
 */
proto.order.ListIDsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ListIDsResponse;
  return proto.order.ListIDsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ListIDsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ListIDsResponse}
 */
proto.order.ListIDsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ListIDsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ListIDsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ListIDsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListIDsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order.ListIDsResponse.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.ListIDsResponse} returns this
 */
proto.order.ListIDsResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.ListIDsResponse} returns this
 */
proto.order.ListIDsResponse.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.ListIDsResponse} returns this
 */
proto.order.ListIDsResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ListOrdersRequest}
 */
proto.order.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ListOrdersRequest;
  return proto.order.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ListOrdersRequest}
 */
proto.order.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.order.ListOrdersRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order.ListOrdersRequest} returns this
*/
proto.order.ListOrdersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ListOrdersRequest} returns this
 */
proto.order.ListOrdersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ListOrdersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.ListOrdersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ListOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ListOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ListOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.OrderResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ListOrdersResponse}
 */
proto.order.ListOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ListOrdersResponse;
  return proto.order.ListOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ListOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ListOrdersResponse}
 */
proto.order.ListOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.OrderResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ListOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ListOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ListOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.OrderResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.order.ListOrdersResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.order.ListOrdersResponse} returns this
*/
proto.order.ListOrdersResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ListOrdersResponse} returns this
 */
proto.order.ListOrdersResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ListOrdersResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.OrderResponse items = 2;
 * @return {!Array<!proto.model.OrderResponse>}
 */
proto.order.ListOrdersResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.OrderResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderResponse, 2));
};


/**
 * @param {!Array<!proto.model.OrderResponse>} value
 * @return {!proto.order.ListOrdersResponse} returns this
*/
proto.order.ListOrdersResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.OrderResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderResponse}
 */
proto.order.ListOrdersResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.OrderResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.ListOrdersResponse} returns this
 */
proto.order.ListOrdersResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ListHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ListHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ListHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ListHistoryRequest}
 */
proto.order.ListHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ListHistoryRequest;
  return proto.order.ListHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ListHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ListHistoryRequest}
 */
proto.order.ListHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ListHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ListHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ListHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ListHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.ListHistoryRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.ListHistoryRequest} returns this
 */
proto.order.ListHistoryRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional filter.Request filter = 2;
 * @return {?proto.filter.Request}
 */
proto.order.ListHistoryRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 2));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order.ListHistoryRequest} returns this
*/
proto.order.ListHistoryRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ListHistoryRequest} returns this
 */
proto.order.ListHistoryRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ListHistoryRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ExportOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ExportOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ExportOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ExportOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ExportOrdersRequest}
 */
proto.order.ExportOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ExportOrdersRequest;
  return proto.order.ExportOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ExportOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ExportOrdersRequest}
 */
proto.order.ExportOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ExportOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ExportOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ExportOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ExportOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.order.ExportOrdersRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order.ExportOrdersRequest} returns this
*/
proto.order.ExportOrdersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ExportOrdersRequest} returns this
 */
proto.order.ExportOrdersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ExportOrdersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ExportOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ExportOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ExportOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ExportOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_export: (f = msg.getExport()) && protobuf_filter_export_pb.ExportResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ExportOrdersResponse}
 */
proto.order.ExportOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ExportOrdersResponse;
  return proto.order.ExportOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ExportOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ExportOrdersResponse}
 */
proto.order.ExportOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_export_pb.ExportResponse;
      reader.readMessage(value,protobuf_filter_export_pb.ExportResponse.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ExportOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ExportOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ExportOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ExportOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_export_pb.ExportResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.ExportResponse export = 1;
 * @return {?proto.filter.ExportResponse}
 */
proto.order.ExportOrdersResponse.prototype.getExport = function() {
  return /** @type{?proto.filter.ExportResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_export_pb.ExportResponse, 1));
};


/**
 * @param {?proto.filter.ExportResponse|undefined} value
 * @return {!proto.order.ExportOrdersResponse} returns this
*/
proto.order.ExportOrdersResponse.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.ExportOrdersResponse} returns this
 */
proto.order.ExportOrdersResponse.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.ExportOrdersResponse.prototype.hasExport = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.CancelOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.CancelOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.CancelOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.CancelOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CancelOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    comment: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.CancelOrdersRequest}
 */
proto.order.CancelOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.CancelOrdersRequest;
  return proto.order.CancelOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.CancelOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.CancelOrdersRequest}
 */
proto.order.CancelOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.CancelOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.CancelOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.CancelOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CancelOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order.CancelOrdersRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.CancelOrdersRequest} returns this
 */
proto.order.CancelOrdersRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.CancelOrdersRequest} returns this
 */
proto.order.CancelOrdersRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.CancelOrdersRequest} returns this
 */
proto.order.CancelOrdersRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.order.CancelOrdersRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.CancelOrdersRequest} returns this
 */
proto.order.CancelOrdersRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.CancelOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order.CancelOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.CancelOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CancelOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.CancelOrdersResponse}
 */
proto.order.CancelOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.CancelOrdersResponse;
  return proto.order.CancelOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.CancelOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.CancelOrdersResponse}
 */
proto.order.CancelOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.CancelOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.CancelOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.CancelOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.CancelOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateDispatchLoadingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateDispatchLoadingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateDispatchLoadingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateDispatchLoadingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dispatchLoadingStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateDispatchLoadingStatusRequest}
 */
proto.order.UpdateDispatchLoadingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateDispatchLoadingStatusRequest;
  return proto.order.UpdateDispatchLoadingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateDispatchLoadingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateDispatchLoadingStatusRequest}
 */
proto.order.UpdateDispatchLoadingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {!proto.model.DispatchLoadingStatus} */ (reader.readEnum());
      msg.setDispatchLoadingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateDispatchLoadingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateDispatchLoadingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateDispatchLoadingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateDispatchLoadingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDispatchLoadingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.UpdateDispatchLoadingStatusRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateDispatchLoadingStatusRequest} returns this
 */
proto.order.UpdateDispatchLoadingStatusRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional model.DispatchLoadingStatus dispatch_loading_status = 2;
 * @return {!proto.model.DispatchLoadingStatus}
 */
proto.order.UpdateDispatchLoadingStatusRequest.prototype.getDispatchLoadingStatus = function() {
  return /** @type {!proto.model.DispatchLoadingStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.DispatchLoadingStatus} value
 * @return {!proto.order.UpdateDispatchLoadingStatusRequest} returns this
 */
proto.order.UpdateDispatchLoadingStatusRequest.prototype.setDispatchLoadingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.UpdateDispatchDeliveryStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.UpdateDispatchDeliveryStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.UpdateDispatchDeliveryStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateDispatchDeliveryStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dispatchDeliveryStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.UpdateDispatchDeliveryStatusRequest}
 */
proto.order.UpdateDispatchDeliveryStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.UpdateDispatchDeliveryStatusRequest;
  return proto.order.UpdateDispatchDeliveryStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.UpdateDispatchDeliveryStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.UpdateDispatchDeliveryStatusRequest}
 */
proto.order.UpdateDispatchDeliveryStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {!proto.model.DispatchDeliveryStatus} */ (reader.readEnum());
      msg.setDispatchDeliveryStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.UpdateDispatchDeliveryStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.UpdateDispatchDeliveryStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.UpdateDispatchDeliveryStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.UpdateDispatchDeliveryStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDispatchDeliveryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.UpdateDispatchDeliveryStatusRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.UpdateDispatchDeliveryStatusRequest} returns this
 */
proto.order.UpdateDispatchDeliveryStatusRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional model.DispatchDeliveryStatus dispatch_delivery_status = 2;
 * @return {!proto.model.DispatchDeliveryStatus}
 */
proto.order.UpdateDispatchDeliveryStatusRequest.prototype.getDispatchDeliveryStatus = function() {
  return /** @type {!proto.model.DispatchDeliveryStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.DispatchDeliveryStatus} value
 * @return {!proto.order.UpdateDispatchDeliveryStatusRequest} returns this
 */
proto.order.UpdateDispatchDeliveryStatusRequest.prototype.setDispatchDeliveryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.ApproveByBrokerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.ApproveByBrokerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.ApproveByBrokerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ApproveByBrokerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.ApproveByBrokerRequest}
 */
proto.order.ApproveByBrokerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.ApproveByBrokerRequest;
  return proto.order.ApproveByBrokerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.ApproveByBrokerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.ApproveByBrokerRequest}
 */
proto.order.ApproveByBrokerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.ApproveByBrokerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.ApproveByBrokerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.ApproveByBrokerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.ApproveByBrokerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.ApproveByBrokerRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.ApproveByBrokerRequest} returns this
 */
proto.order.ApproveByBrokerRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.SetCheckedDocTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order.SetCheckedDocTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.SetCheckedDocTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.SetCheckedDocTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    docTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isChecked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.SetCheckedDocTypeRequest}
 */
proto.order.SetCheckedDocTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.SetCheckedDocTypeRequest;
  return proto.order.SetCheckedDocTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.SetCheckedDocTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.SetCheckedDocTypeRequest}
 */
proto.order.SetCheckedDocTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocTypeId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsChecked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.SetCheckedDocTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.SetCheckedDocTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.SetCheckedDocTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.SetCheckedDocTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsChecked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string order_item_id = 1;
 * @return {string}
 */
proto.order.SetCheckedDocTypeRequest.prototype.getOrderItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.SetCheckedDocTypeRequest} returns this
 */
proto.order.SetCheckedDocTypeRequest.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string doc_type_id = 2;
 * @return {string}
 */
proto.order.SetCheckedDocTypeRequest.prototype.getDocTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.SetCheckedDocTypeRequest} returns this
 */
proto.order.SetCheckedDocTypeRequest.prototype.setDocTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_checked = 3;
 * @return {boolean}
 */
proto.order.SetCheckedDocTypeRequest.prototype.getIsChecked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.SetCheckedDocTypeRequest} returns this
 */
proto.order.SetCheckedDocTypeRequest.prototype.setIsChecked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.order);
